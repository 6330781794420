<template>
  <div>
    <v-input v-model="forms.name" label="Naam" placeholderText="Voorbeeld (2861EW)"
             :show-errors="showErrors" :disabled="disabled"/>
    <v-input v-model="forms.description" label="Beschrijving" placeholderText="Voorbeeld (2861EW)"
             :show-errors="showErrors" :disabled="disabled"/>
    <v-checkbox v-model="forms.ignored"
                label="Word genegeerd"
                placeholderText="Negeer optie op portalen"
                :show-errors="showErrors"
                :disabled="disabled"/>
    <v-validate-button :disabled="disabled"
                       :form="forms"
                       @valid="valid"
                       @error="setError"/>
  </div>
</template>

<script>
import validations from "@/components/forms/vehicleOptionGroup/vehicleOptionGroup.validator";
import baseForm from "@/components/shared/baseForm";

export default {
  extends: baseForm,
  mounted() {
    if (Object.keys(this.data).length === 0) {
      this.data = {}
    }
  },
  data() {
    return {
      forms: validations,
    }
  },
  watch: {
    data: {
      handler: function (_, data) {
        this.data = this.$v.inject(validations, data)
      }
    }
  }
}
</script>