<template>
  <d-container fluid>
    <pageTitle title="Voertuig opties"/>
    <d-row>
      <vCard header="Voertuig optie" col="8" fluid>
        <table class="table" style="width: 100%;">
          <template v-for="vehicleOption in vehicleOptionsWithoutGroup">
            <vehicleOptionTableRow :vehicleOption="vehicleOption" @delete="removeFromVehicleOptionsWithoutGroup"
                                   :key="vehicleOption.id"/>
          </template>
        </table>
      </vCard>
      <vCard header="Voertuig optie" col="4" fluid>
        <vTable :fields="fields" :actions="actions" :data="data"/>
      </vCard>
    </d-row>
  </d-container>
</template>

<script>
import vehicleOptionTableRow from '../components/VehicleOptionTableRow'

export default {
  name: 'Voorbeeld',
  components: {vehicleOptionTableRow},
  data() {
    return {
      actions: [
        {
          icon: '<i class="material-icons">visibility</i>',
          fn: (vehicleOptionCategory) => {
            this.$router.push({
              name: 'dashboard/inkoop/vehicleOptions/vehicleOptionsByCategory',
              params: {category_id: vehicleOptionCategory.id}
            })
          }
        }
      ],
      data: null,
      fields: [
        {
          name: 'name',
          title: 'Optie category',
          sortField: 'name'
        },
        'actions'
      ],
      vehicleOptionsWithoutGroup: null
    }
  },
  created() {
    this.getVehicleOptionsWithoutGroup()
    this.getVehicleOptionCategories()
  },
  mounted() {
  },
  methods: {
    async getVehicleOptionsWithoutGroup() {
      let {data} = await this.$api.vehicleOption.getOptionsWithoutGroup()
      this.vehicleOptionsWithoutGroup = data
    },
    async getVehicleOptionCategories() {
      let {data} = await this.$api.vehicleOptionCategory.list()
      this.data = data
    },
    removeFromVehicleOptionsWithoutGroup(e) {
      let {vehicleOptionsWithoutGroup} = this
      const index = vehicleOptionsWithoutGroup.indexOf(e);
      if (index > -1) {
        vehicleOptionsWithoutGroup.splice(index, 1);
      }
    }
  }
}
</script>

<style scoped>

</style>
