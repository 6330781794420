<template>
  <tr>
    <td>{{ vehicleOption.name }}</td>
    <td>
      <selectVehicleOptionGroup @group="setGroup"/>
    </td>
    <td>
      <button class="btn btn-primary" @click="addVehicleOptionToGroup" style="margin: 0 auto; display: block;">
        <i class="material-icons">save</i> Opslaan
      </button>
    </td>
  </tr>
</template>

<script>
import selectVehicleOptionGroup from "@/components/forms/selectVehicleOptionGroup";

export default {
  name: 'vehicleOptionTableRow',
  components: {selectVehicleOptionGroup},
  props: {
    vehicleOption: {
      required: true
    }
  },
  data() {
    return {
      vehicleOptionsWithoutGroup: null,
      selectedVehicleGroup: null
    }
  },
  mounted() {
  },
  methods: {
    async addVehicleOptionToGroup() {
      const {selectedVehicleGroup, vehicleOption} = this
      if (selectedVehicleGroup) {
        try {
          this.$api.vehicleOption.setGroup(vehicleOption, selectedVehicleGroup)
          this.$emit('delete', vehicleOption)
        } catch (e) {
        }
      } else {
        alert('selecteer eerst een groep')
      }
    },
    setGroup(group) {
      this.selectedVehicleGroup = group
    }
  }
}
</script>
