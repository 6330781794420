<template>
  <div>
    <formVehicleOptionGroup ref="vehicleOptionGroup" @valid="createVehicleOptionGroup"/>
  </div>
</template>

<script>
import formVehicleOptionGroup from "@/components/forms/vehicleOptionGroup/formVehicleOptionGroup";

export default {
  components: {
    formVehicleOptionGroup
  },
  props: {
    category: {
      required: true
    }
  },
  methods: {
    async createVehicleOptionGroup({name, ignored, description}) {
      try {
        let {data} = await this.$api.vehicleOptionGroup.create(name, description, !!ignored, this.category)
        this.$refs.vehicleOptionGroup.submit(data, true, 'Voertuig optie groep toegevoegd')
      } catch (e) {
      }
    }
  }
}
</script>
