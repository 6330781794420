<template>
  <div>
    <v-select label="Category"
              placeHolderText="Selecteer category"
              v-model="vehicleOptionCategory"
              :options="vehicleOptionCategories"
              optionsKey="id"
              optionsValue="name"
              :show-errors="false"
              :disabled="false">
    </v-select>
    <v-select label="Groep"
              placeHolderText="Selecteer groep"
              v-model="vehicleOptionGroup"
              :options="vehicleOptionGroups"
              optionsKey="id"
              optionsValue="name"
              :show-errors="false"
              :disabled="false">
      <template #beforeList>
        <button class="btn btn-primary" @click="createVehicleOptionGroup">
          <i class="material-icons">add</i> Voeg Groep toe
        </button>
      </template>
      <template #noOptions>
        Selecteer eerst een category
      </template>
    </v-select>
    <d-modal v-if="modalCreateVehicleOptionGroup" @close="modalCreateVehicleOptionGroupCloseHandler">
      <d-modal-header>
        <d-modal-title>Groep toevoegen - {{ vehicleOptionCategory.value.name }}</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <addVehicleOptionGroup @submit="addVehicleOptionGroup" :category="vehicleOptionCategory.value"/>
      </d-modal-body>
    </d-modal>
  </div>
</template>

<script>
import addVehicleOptionGroup from "@/components/forms/vehicleOptionGroup/addVehicleOptionGroup";

export default {
  data() {
    return {
      vehicleOptionCategories: null,
      vehicleOptionCategory: null,
      vehicleOptionGroups: null,
      vehicleOptionGroup: null,
      modalCreateVehicleOptionGroup: false
    }
  },
  created() {
    this.getVehicleOptionCategories()
  },
  components: {
    addVehicleOptionGroup
  },
  watch: {
    vehicleOptionCategory: {
      handler() {
        if (!!this.vehicleOptionCategory?.value) {
          this.getVehicleOptionGroups()
          this.vehicleOptionGroup = null
        }
      }
    },
    vehicleOptionGroup: {
      handler() {
        if (!!this.vehicleOptionGroup?.value) {
          this.$emit('group', this.$v.extract([this.vehicleOptionGroup])[0])
        }
      }
    }
  },
  methods: {
    createVehicleOptionGroup() {
      this.modalCreateVehicleOptionGroup = true
    },
    modalCreateVehicleOptionGroupCloseHandler() {
      this.modalCreateVehicleOptionGroup = false
    },
    async getVehicleOptionCategories() {
      let {data} = await this.$api.vehicleOptionCategory.list()
      this.vehicleOptionCategories = data
    },
    createVehicleOptionCategory() {
      console.log('Create vehicle option category')
    },
    async getVehicleOptionGroups() {
      let {data} = await this.$api.vehicleOptionGroup.getByCategory(this.vehicleOptionCategory.value)
      this.vehicleOptionGroups = data
    },
    addVehicleOptionGroup(e) {
      this.vehicleOptionGroups.push(e)
      this.vehicleOptionGroup = e
      // Close create modal
      this.modalCreateVehicleOptionGroupCloseHandler()
    }
  }
}
</script>