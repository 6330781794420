import {fixedLength, minLength, required} from "@/utils/validator";

export default {
    name: {
        validations: {
            required,
        }
    },
    description: {
        validations: {
        }
    },
    ignored: {
    },
}